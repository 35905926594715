<template>
  <v-col cols="12" md="4" class="px-4">
    <v-card
      elevation="8"
    >
      <v-toolbar
        color="primary"
        dark
        :dense="$vuetify.breakpoint.smAndDown"
      >
        <v-toolbar-title>{{title}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="sortValue = !sortValue"
        >
          <v-icon v-if="sortValue">mdi-arrow-up</v-icon>
          <v-icon v-else>mdi-arrow-down</v-icon>
        </v-btn>
      </v-toolbar>
      <v-system-bar
        window
        light
        v-if="oldData.length"
      >
        <v-icon color="red">mdi-alert</v-icon>
        <span>{{oldData.length}} expired {{individualTitle.toLocaleLowerCase()}}s</span>
        <v-spacer></v-spacer>
        <v-dialog v-model="showExpired" width="500">
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              color="primary lighten-4"
              v-on="on">
              <v-icon>mdi-open-in-new</v-icon> View
            </v-btn>
          </template>
          <v-card>
            <v-card-title>Expired {{individualTitle}}s<v-spacer></v-spacer>
              <v-btn @click="showExpired = false" icon><v-icon>mdi-close</v-icon></v-btn></v-card-title>
            <v-data-iterator
              :items="oldData"
              sort-by="arrivalTime"
              :sort-desc="sortValue"
            >
              <template v-slot:default="props">
                <template v-for="(cat, index) in props.items">
                  <ArrivalListItem
                    v-bind:key="`${cat.id}-list-item`"
                    :cat-item="cat"
                    :timeLabel="timeLabel"
                  />
                  <v-divider v-if="index < data.length" :key="'divider-'+index"></v-divider>
                </template>

              </template>
            </v-data-iterator>
          </v-card>
        </v-dialog>
      </v-system-bar>

      <v-list
        v-if="currentData.length"
      >
        <v-data-iterator
          :items="currentData"
          sort-by="arrivalTime"
          :sort-desc="sortValue"
        >
          <template v-slot:default="props">
            <template v-for="(cat, index) in props.items">
              <v-slide-x-transition :key="`transition${cat.id}`">
                <ArrivalListItem
                  v-bind:key="`${cat.id}-list-item`"
                  :cat-item="cat"
                  :timeLabel="timeLabel"
                />
              </v-slide-x-transition>
              <v-divider v-if="index < data.length" :key="'divider-'+index"></v-divider>
            </template>

          </template>
        </v-data-iterator>
      </v-list>
      <v-list v-else>
        <v-list-item>
          <v-list-item-title>No Collections Found</v-list-item-title>
        </v-list-item>
      </v-list>

    </v-card>
  </v-col>
</template>

<script>
import moment from 'moment'
import ArrivalListItem from './ArrivalListItem'

export default {
  name: 'ArrivalList',
  components: { ArrivalListItem },
  data () {
    return {
      sortOverride: '',
      showExpired: false
    }
  },
  props: {
    catsName: String,
    title: String,
    timeLabel: String,
    mainIcon: String,
    descending: {
      type: Boolean,
      default: false
    },
    individualTitle: {
      type: String,
      default: 'Pickup'
    },
    minutesUntilExpired: {
      type: Number,
      default: 120
    },
    catsData: Array
  },

  computed: {
    data: function () {
      return this.catsData ? this.catsData : this.$store.state.instore[this.catsName]
    },
    oldData: function () {
      return this.data.filter(item => {
        const age = moment().diff(moment(item.arrivalTime), 'minutes')
        return age > this.minutesUntilExpired
      })
    },
    currentData: function () {
      return this.data.filter(item => {
        const age = moment().diff(moment(item.arrivalTime), 'minutes')
        return age <= this.minutesUntilExpired
      })
    },
    sortValue: {
      get: function () {
        if (this.sortOverride === '') {
          return this.descending
        } else {
          return this.sortOverride
        }
      },
      set: function (newValue) {
        this.sortOverride = newValue
      }

    }
  },
  methods: {
    invertOrdering: function () {
      this.sortOverride = (!this.sortValue)
    }
  }
}
</script>

<style scoped>
</style>
