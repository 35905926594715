<template>
  <v-container>
    <page-title-block :store-name="storeName" page-name="Customer Arrivals"/>

    <v-row>
      <ArrivalList
        :cats-data="clickAndParkUnion"
        main-icon="mdi-marker-check"
        time-label="Parked At"
        title="Click & Park Arrivals"
        :descending="true"
        individual-title="Arrival"
        :minutes-until-expired="30"
      ></ArrivalList>

      <ArrivalList
        cats-name="arrivedCATS"
        main-icon="mdi-marker-check"
        time-label="Planned to arrive at"
        title="Confirmed Arriving Soon"
        :descending="true"
        individual-title="Arrival"
        :minutes-until-expired="60"
      ></ArrivalList>

      <ArrivalList
        cats-name="timeSetCATS"
        main-icon="mdi-car"
        time-label="Scheduled For"
        title="Scheduled Pickups"
      ></ArrivalList>

    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import ArrivalList from '../components/ArrivalList'
import PageTitleBlock from '../components/PageTitleBlock'

export default {
  name: 'StoreView',
  components: { PageTitleBlock, ArrivalList },
  data () {
    return {
      loading: false,
      error: null,
      timer: ''
    }
  },
  filters: {
    menuSummariser: (text) => {
      return text ? text.split(' ').map(word => word.replace(/[aeiou]/ig, '')).map(word => word.slice(0, 3)).join(' ') : ''
    }
  },
  computed: {
    ...mapState('instore', ['stores', 'arrivedCATS', 'timeSetCATS', 'clickAndParkCATS', 'clickAndParkAccepted', 'clickAndParkRejected']),
    storeId: function () {
      return this.$route.params.storeId
    },
    storeName: function () {
      const storeDetails = this.stores.find(item => item.storeId === this.storeId)
      return storeDetails ? storeDetails.storeName : 'Unknown'
    },
    clickAndParkUnion: function () {
      return [...this.clickAndParkAccepted, ...this.clickAndParkCATS, ...this.clickAndParkRejected]
    }
  },
  created () {
    this.fetchData()
    this.timer = setInterval(this.fetchData, 10000)
    this.$store.dispatch('instore/setDefaultInLocalStorageIfNotSet', {
      propertyName: 'defaultStore',
      value: this.storeId,
      localStorage
    }).then(valueSet => {
      if (valueSet) {
        this.$emit('defaultsUpdated')
      }
    })
  },
  watch: {
    // call again the method if the route changes
    $route: 'fetchData'
  },
  methods: {
    showSearchPopup: function () {
      this.showSearch = true
    },
    fetchData: async function () {
      this.error = null
      this.loading = true
      const token = await this.$auth.getTokenSilently()
      return this.$store.dispatch('instore/loadStoreData', { storeId: this.storeId, token })
        .then(() => (this.loading = false))
        .catch(err => (this.error = err))
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>

<style scoped>

</style>
