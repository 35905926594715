<template>
  <v-row>
    <page-title-block page-name="Customer Journey Start Points" :store-name="storeName"></page-title-block>
    <v-col cols="12">
      <GmapMap
        :center="mapCentre"
        :zoom="12"
        map-type-id="terrain"
        style="height: 800px"
      >
        <gmap-marker
          :position="mapCentre"
          icon="https://www.wickes.co.uk/_ui/addons/b2caddon/shared/common/images/apple-touch-icon-57x57.png"
        ></gmap-marker>
        <GmapMarker
          :key="index"
          v-for="(m, index) in customers"
          :position="m"
          @click="center=m"
        />
      </GmapMap>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import PageTitleBlock from '@/components/PageTitleBlock'

const {
  VUE_APP_API_URL
} = process.env

export default {
  name: 'CustomerMap',
  components: { PageTitleBlock },
  data () {
    return {
      mapCentre: { lat: 53, lng: 0 },
      customers: [
        {
          lat: 53.1,
          lng: 1.2
        }
      ]
    }
  },
  computed: {
    ...mapState('instore', ['stores']),
    storeId: function () {
      return this.$route.params.storeId
    },
    storeName: function () {
      const storeDetails = this.stores.find(item => item.storeId === this.storeId)
      return storeDetails ? storeDetails.storeName : 'Unknown'
    }
  },
  mounted () {
    this.updateCentre()
    this.fetchPoints()
  },
  methods: {
    updateCentre: function () {
      return Promise.resolve(`https://hybris-services.external.wickes.co.uk/store-service/${this.storeId}`)
        .then(url => fetch(url))
        .then(data => data.json())
        .then(data => data[0])
        .then(result => result.location)
        .then(location => ({
          lat: location.latitude,
          lng: location.longitude
        }))
        .then(centre => (this.mapCentre = centre))
    },
    fetchPoints: function () {
      this.$auth.getTokenSilently()
        .then(token => fetch(`${VUE_APP_API_URL}/admin/store/${this.storeId}/customer-locations`, {
          headers: {
            Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
          }
        }))
        .then(response => response.json())
        .then(entries => (this.customers = entries
          .map(entry => entry.customerLocation)
          .map(location => {
            console.log(location)
            return {
              lat: location.latitude,
              lng: location.longitude
            }
          })
        ))
    }
  }
}
</script>

<style scoped>

</style>
