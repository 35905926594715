<template>
  <div class="py-1">
    <v-slide-group
      show-arrows
    >
      <v-slide-item
        v-if="catItem.parkingSlot === 'none' || catItem.parkingSlot === ''"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-chip
              class="mx-1"
              v-if="!(catItem.catsStatus === 'customerArrived')"
              :color="catItem.arrivalTime | lateArrivalColouriser"
              v-on="on"
            >
              <v-icon :x-small="$vuetify.breakpoint.smAndDown" class="mr-1">mdi-clock</v-icon>{{catItem.arrivalTime | shortTime}}
            </v-chip>
            <v-chip
              class="mx-1"
              v-else
              :color="catItem.eta | lateArrivalColouriser"
              v-on="on"
            >
              ETA: {{ (catItem.eta || catItem.arrivalTime) | eta }}
            </v-chip>
          </template>
          <span>{{timeLabel}} {{catItem.arrivalTime | shortTime}}</span>
          <span v-if="catItem.eta">, now expected at {{catItem.eta | shortTime}}</span>
        </v-tooltip>
      </v-slide-item>

      <v-slide-item
        v-else
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-chip
              class="mx-1"
              color="primary"
              v-on="on"
            >
              <v-icon
                class="mr-1"
              >mdi-car</v-icon>{{catItem.parkingSlot}}
            </v-chip>
          </template>
          <span>Parking Space</span>
        </v-tooltip>
      </v-slide-item>

      <v-slide-item
        v-if="catItem.registrationNumber"
      >
        <v-chip
          class="text-uppercase mx-1"
          label
          color="yellow"
        ><b>{{catItem.registrationNumber}}</b></v-chip>
      </v-slide-item>

      <v-slide-item
        v-if="catItem.parkingSlot !== 'none' && catItem.parkingSlot !== ''"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-chip
              class="mx-1"
              :color="catItem.arrivalTime | lateArrivalColouriser"
              v-on="on"
            >
              <v-icon
                class="mr-1"
                :x-small="$vuetify.breakpoint.smAndDown"
              >mdi-clock</v-icon> {{catItem.arrivalTime | shortTime}}
            </v-chip>
          </template>
          <span>{{timeLabel}}: {{catItem.arrivalTime | shortTime}}</span>
        </v-tooltip>
      </v-slide-item>

      <v-slide-item v-if="catItem.orderDetails">
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on }">
            <v-chip
              class="mx-1"
              v-on="on"
            >
              <v-icon
                class="mr-1"
              >mdi-basket</v-icon> {{ catItem.orderDetails.lineItems.length }}
            </v-chip>
          </template>
          <span>Order Contents:</span><br>
          <template v-for="item in catItem.orderDetails.lineItems">
            <span
              :key="`basket-span-${item.sku}-${catItem.id}`"
            >{{ item.qty }} x {{ item.name }} ({{item.sku}})</span>
            <br :key="`basket-br-${item.sku}-${catItem.id}`">
          </template>
        </v-tooltip>
      </v-slide-item>
    </v-slide-group>
    <v-list-group
      class="my-1"
      :value="false"
      :key="'entry-'+catItem.id"
      :class="catItem.catsStatus === 'clickAndParkRejected' ? 'red' : ''"
      :disabled="catItem.catsStatus === 'clickAndParkRequested' && cutoffTimeNumber > 0"
      :ripple="catItem.catsStatus !== 'clickAndParkRequested'"
    >
      <template v-slot:activator>
        <v-list-item-content class="pt-1 mb-1 pl-0">
          <v-col cols="12" class="py-1 px-1">
            <v-row>
              <v-col cols="12"
                     class="headline font-weight-bold py-0"
              >{{catItem.orderId}}</v-col>
              <v-col cols="12"
                     v-if="typeof catItem.customerName === 'string'"
                     class="text-uppercase py-0 pb-2"
              >{{catItem.customerName}}</v-col>

              <v-card
                class="mx-auto py-1"
                v-if="catItem.catsStatus === 'clickAndParkRequested'"
                :color="cutoffTimeNumber > 0 ? '' : 'red'"
              >
                <v-card-title class="pt-1">
                  Click & Park Request
                </v-card-title>
                <v-card-subtitle class="pb-1">
                  {{countDownText}}
                </v-card-subtitle>
                <v-card-actions
                  v-if="cutoffTimeNumber > 0"
                  class="justify-center pt-0"
                >
                  <div class="text-center">
                    <v-btn
                      color="green"
                      aria-label="Accept Click & Park"
                      @click="markAsAcknowledged(catItem.id, catItem.parkingSlot, true)"
                      block
                      class="mb-1"
                    >
                      Accept<v-icon>mdi-check</v-icon>
                    </v-btn>
                    <v-btn
                      color="red"
                      aria-label="Reject Click & Park"
                      @click="markAsAcknowledged(catItem.id, catItem.parkingSlot, false)"
                      block
                    >
                      Reject<v-icon>mdi-cancel</v-icon>
                    </v-btn>
                  </div>
                </v-card-actions>
              </v-card>

            </v-row>
          </v-col>
        </v-list-item-content>

      </template>
      <v-card
        flat
      >
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
              <tr>
                <td>Order ID</td>
                <td>{{catItem.orderId}}</td>
              </tr>
              <tr>
                <td>Customer Name</td>
                <td>{{catItem.customerName}}</td>
              </tr>
              <tr v-if="catItem.registrationNumber">
                <td>Car Registration</td>
                <td class="text-uppercase">{{catItem.registrationNumber}}</td>
              </tr>
              <tr v-if="catItem.eta">
                <td>ETA</td>
                <td>{{catItem.eta | longDate}}</td>
              </tr>
              <tr>
                <td>Order Picked</td>
                <td>{{catItem.date | longDate}}</td>
              </tr>
              <tr>
                <td>Customer Phone</td>
                <td>{{catItem.number}}</td>
              </tr>
              <tr>
                <td>Scheduled Arrival Time</td>
                <td>{{catItem.arrivalTime | longDate}}</td>
              </tr>
              <tr>
                <td>Last Update</td>
                <td>{{catItem.lastUpdateTime | longDate}}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="warning"
            @click="hidden(catItem.id)"
          >Hide</v-btn>
          <!--                    <v-btn>Edit</v-btn>-->
          <v-btn
            class="secondary"
            @click="collected(catItem.id)"
          >Collected</v-btn>
        </v-card-actions>
      </v-card>
    </v-list-group>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)
dayjs.extend(duration)

export default {
  name: 'ArrivalListItem',
  props: ['catItem', 'timeLabel'],
  data () {
    return {
      loading: false,
      error: '',
      expirationCutOff: 5
    }
  },
  filters: {
    eta: function (value) {
      return dayjs(value).fromNow()
    },
    colorMap: function (value) {
      const now = dayjs()
      if (now.isAfter(value)) {
        return 'warning'
      } else {
        return 'default'
      }
    },
    lateArrivalColouriser: function (value) {
      const diff = dayjs(value).diff(dayjs(), 'minutes')
      const colours = ['lighten-5', 'lighten-4', 'lighten-3', 'lighten-2', 'lighten-1', '', 'darken-1', 'darken-2', 'darken-3', 'darken-4', 'darken-5']
      if (diff > 0) {
        return 'default'
      } else {
        const index = Math.min(Math.abs(diff), 10)
        return 'red ' + colours[index]
      }
    }
  },
  computed: {
    ...mapState(['currentTime']),
    cutoffTimeNumber: function () {
      if (dayjs(this.catItem.lastUpdateTime).add(this.expirationCutOff, 'minutes').isBefore(dayjs())) {
        return 0
      }
      return dayjs(this.catItem.lastUpdateTime).add(this.expirationCutOff, 'minutes').diff(this.currentTime)
    },
    countDownText: function () {
      if (this.cutoffTimeNumber <= 0) {
        return 'Expired'
      } else {
        return dayjs.duration(
          this.cutoffTimeNumber
        ).format('m:ss') + ' until expiration'
      }
    }
  },
  methods: {
    collected: async function (id) {
      const token = await this.$auth.getTokenSilently()
      this.$store.dispatch('instore/updateStatus', {
        storeId: this.$route.params.storeId,
        catsID: id,
        status: 'collected',
        collectedTime: dayjs().format(),
        token: token
      })
        .then(() => {
          this.loading = false
        })
        .then(() => this.$gtag.event('MarkAsCollected', {
          event_category: 'ColleagueAction',
          event_label: this.$route.params.storeId
        }))
        .catch(err => {
          console.log(err)
          this.error = err
          this.loading = false
        })
    },
    markAsAcknowledged: function (id, parkingSlot, accept) {
      return Promise.resolve(this.$auth.getTokenSilently())
        .then(token => this.$store.dispatch('instore/updateStatus', {
          storeId: this.$route.params.storeId,
          catsID: id,
          status: accept ? 'clickAndParkAccepted' : 'clickAndParkRejected',
          parkingSlot: parkingSlot,
          token: token
        }))
        .then(() => {
          this.loading = false
        })
        .then(() => this.$gtag.event(accept ? 'CnPAccepted' : 'CnPRejected', {
          event_category: 'ColleagueAction',
          event_label: this.$route.params.storeId
        }))
        .catch(err => {
          console.log(err)
          this.error = err
          this.loading = false
        })
    },
    hidden: async function (id) {
      const token = await this.$auth.getTokenSilently()
      this.$store.dispatch('instore/updateStatus', {
        storeId: this.$route.params.storeId,
        catsID: id,
        status: 'hidden',
        token: token
      })
        .then(() => {
          this.loading = false
        })
        .then(() => this.$gtag.event('HideEntry', {
          event_category: 'ColleagueAction',
          event_label: this.$route.params.storeId
        }))
        .catch(err => {
          console.log(err)
          this.error = err
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
